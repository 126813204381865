import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgxLoadingModule } from 'ngx-loading';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { wAlertFeatureKey, wAlertReduercer } from './reducers/w-alert.reducer';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { itemFeatureKey, itemReduercer } from './reducers/item.reducer';
import { NoticeLayoutComponent } from './layout/notice-layout/notice-layout.component';
import { LoginEffect2 } from './effects/system/login2.effect';
import {
	login2SearchFeatureKey,
	login2SearchReducer,
} from './reducers/system/login2.reducer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
	declarations: [AppComponent, NotFoundComponent, NoticeLayoutComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		StoreModule.forRoot({}),
		EffectsModule.forRoot([]),
		NgxLoadingModule.forRoot({ fullScreenBackdrop: true }),

		StoreModule.forFeature(itemFeatureKey, itemReduercer),
		StoreModule.forFeature(wAlertFeatureKey, wAlertReduercer),
		StoreModule.forFeature(login2SearchFeatureKey, login2SearchReducer),

		EffectsModule.forFeature([LoginEffect2]),
		BrowserAnimationsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpTranslateLoader,
				deps: [HttpClient],
			},
		}),
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-right',
			maxOpened: 4
		})
	],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en' }],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
