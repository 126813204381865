import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@enviroments/environment';
import { MemberModel } from '@app/models/system/member.model';
import { ResultProcessModel } from '@app/models/myoffice/result-process.model';
import { Member } from '@app/models/myoffice/member/member.model';
import { ResultProc } from '@app/models/system/result-proc.model';

@Injectable({
	providedIn: 'root'
})
export class Login2Service {

	constructor(private http: HttpClient) { }

	getLoginInfo(params: any): Observable<MemberModel> {
		// let url = `${environment.apiUrl}/auth/login-userid`;
		let url = `${environment.apiUrl}/auth/login-userid/${params.userid}/${params.password}/${environment.comId}`;
		// return this.http.post<MemberModel>(url, params);
		return this.http.get<MemberModel>(url);
	}

	getLoginInfoOther(params: any): Observable<MemberModel> {
		let url = `${environment.apiUrl}/auth/login-userid-other/${params.userid}/${environment.comId}`;
		return this.http.get<MemberModel>(url);
	}

	coutBonus(params: any): Observable<Number> {
		let url = `${environment.apiUrl}/auth/check-bonus`;
		return this.http.post<Number>(url, params);
	}

	findId(params: any): Observable<HttpResponse<MemberModel>> {
		let url = `${environment.apiUrl}/auth/find-log-id/${params.username}/${params.birthday}/${params.phone}?comId=${environment.comId}`;

		return this.http.request<MemberModel>('get', url, { observe: 'response' });
	}

	findPassWd(params: any): Observable<ResultProcessModel> {
		let url = `${environment.apiUrl}/auth/password/forgot`;

		return this.http.post<ResultProcessModel>(url, params);
	}

	loginUserid2(userid2: string): Observable<Member> {
		let url = `${environment.apiUrl}/auth/login-userid2?userid2=${userid2}&comid=${environment.comId}`;
		return this.http.get<Member>(url);
	}

	findMember(params: any): Observable<HttpResponse<MemberModel>> {
		let url = `${environment.apiUrl}/find-reg-member`;
		return this.http.post<HttpResponse<MemberModel>>(url, params);
	}

	getAccessTokenNaver(code: string): Observable<string> {
		let url = `${environment.apiUrl}/member/getTokenNaver?comId=${environment.comId}&lang=${environment.default_lang}&code=${code}`;
		return this.http.get<string>(url);
	}

	getInfoNaver(token: string): Observable<string> {
		let url = `${environment.apiUrl}/member/getInfoNaver?token=${token}`;
		return this.http.get<string>(url);
	}

	updateLinkAccount(params: any): Observable<ResultProc> {
		let url = `${environment.apiUrl}/login/updateLinkAccount`;
		return this.http.post<ResultProc>(url, params);
	}

	getInformationByUserId(userId: string): Observable<Member> {
		let url = `${environment.apiUrl}/auth/information/link-guest/${userId}/${environment.comId}`;
		return this.http.get<Member>(url);
	}

	getInfoGoogle(params: {}): Observable<MemberModel> {
		let url = `${environment.apiUrl}/google/user/info`;
		return this.http.post<MemberModel>(url, params);
	  }
}

