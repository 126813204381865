import { DateFilterModel } from "@app/models/components/date-filter.model";
import { DateModel } from "@app/models/components/date.model";
import { OrdPdtModel } from "@app/models/shoppingmall/order-pdt.model";
import { PdtDetailModel } from "@app/models/shoppingmall/pdt-detail.model";
import { CommonUtils } from "./common.util";

export class ValidationUtil {

	static isNotNullAndNotEmpty(value: any): boolean {
		if (typeof value !== "undefined" && value !== null) {
			if (typeof value === "string") {
				return value.trim().length > 0;
			} else if (value instanceof Array) {
				return value.length > 0;
			} else if(typeof value === "object"){
				return (value && (Object.keys(value).length > 0));
			}

			return true;
		}

		return false;
	}

	static isNullOrEmpty(value: any): boolean {
		if (typeof value !== "undefined" && value !== null) {
			if (typeof value === "string") {
				return value.trim().length === 0;
			} else if (value instanceof Array) {
				return value.length === 0;
			} else if(typeof value === "object"){
				return (value && (Object.keys(value).length === 0));
			}

			return false;
		}

		return true;
	}

	static isValidDateFilter(filter: DateFilterModel): boolean {
		if (ValidationUtil.isNullOrEmpty(filter)) return false;

		if (ValidationUtil.isNullOrEmpty(filter.fromDate) || ValidationUtil.isNullOrEmpty(filter.toDate)) return false;

		if (ValidationUtil.isNullOrEmpty(filter.fromDate.year) || ValidationUtil.isNullOrEmpty(filter.fromDate.year.value)) return false;
		if (ValidationUtil.isNullOrEmpty(filter.fromDate.month) || ValidationUtil.isNullOrEmpty(filter.fromDate.month.value)) return false;
		if (ValidationUtil.isNullOrEmpty(filter.fromDate.date) || ValidationUtil.isNullOrEmpty(filter.fromDate.date.value)) return false;

		if (ValidationUtil.isNullOrEmpty(filter.toDate.year) || ValidationUtil.isNullOrEmpty(filter.toDate.year.value)) return false;
		if (ValidationUtil.isNullOrEmpty(filter.toDate.month) || ValidationUtil.isNullOrEmpty(filter.toDate.month.value)) return false;
		if (ValidationUtil.isNullOrEmpty(filter.toDate.date) || ValidationUtil.isNullOrEmpty(filter.toDate.date.value)) return false;

		return true;
	}

	static isValidDateModel(date: DateModel): boolean {
		if (ValidationUtil.isNullOrEmpty(date)) return false;

		if (ValidationUtil.isNullOrEmpty(date.year) || ValidationUtil.isNullOrEmpty(date.year.value)) return false;
		if (ValidationUtil.isNullOrEmpty(date.month) || ValidationUtil.isNullOrEmpty(date.month.value)) return false;
		if (ValidationUtil.isNullOrEmpty(date.date) || ValidationUtil.isNullOrEmpty(date.date.value)) return false;

		return true;
	}

	/**
	 * Check if all of value are not empty or null.
	 * @param value the value to check, may be null or empty
	 * @returns true if all value are not empty or null
	 */
	static isAllNotNullAndNotEmpty(...value : any[]): boolean {
		for(let v of value) {
			if(ValidationUtil.isNullOrEmpty(v)) {
				return false;
			}
		}

		return true;
	}

	/**
	 * Check if the value are undefined or null.
	 * @param value the value to check, may be null or undefined
	 * @returns true if the value are undefined or null
	 */
	static isNotNullAndNotUndefined(value: any) : boolean {
		return typeof value !== "undefined" && value !== null;
	}

	/**
	 * Check if any of the value are undefined or null.
	 * @param value the value to check, may be null or undefined
	 * @returns true if the any of value are undefined or null
	 */
	static isAnyNotNullAndNotUndefined(...value: any[]) : boolean {
		for(let v of value) {
			if(!ValidationUtil.isNotNullAndNotUndefined(v)) {
				return false;
			}
		}
		return true;
	}

	static validatePrice(item : PdtDetailModel | OrdPdtModel[] | OrdPdtModel, isLogin : boolean) {
		if(isLogin || CommonUtils.isLinkCart() || CommonUtils.isLinkGuest()) {
			return (item instanceof Array) ? item.filter(v => Number(v.amt) <= 0).length > 0 : (Number(item.amt) <= 0);
		}
		return (item instanceof Array) ? item.filter(v => Number(v.retailAmt) <= 0).length > 0 : (Number(item.retailAmt) <= 0);
	}

  	static isNotEmptyDate(date: string) {
		return this.isNotNullAndNotEmpty(date) && date !== "";
	}

	static isNotEmptyRangeDate(dateFrom: string, dateTo: string) {
		return this.isNotEmptyDate(dateFrom) && this.isNotEmptyDate(dateTo);
	}

	static isIndexOf(data: string, value: string) {
		return data.indexOf(value) !== -1;
	}

	static isAnyIndexOf(value: string, ...data: string[]) {
		for(let v of data) {
			if(!ValidationUtil.isIndexOf(v, value)) {
				return false;
			}
		}
		return true;
	}

}
