import { environment } from "@enviroments/environment";
import { AuthUtil } from "./auth.util";
import { ValidationUtil } from "./validation.util";
import { ConvertUtil } from "./convert.util";
import { CommonConstant } from "../constant/common.constant";
import { OrdPdtModel } from "@app/models/shoppingmall/order-pdt.model";
import { PdtDetailModel } from "@app/models/shoppingmall/pdt-detail.model";
import { CommonUtils } from "./common.util";

export class CartUtil {
  static getParamsAddToCart(pdtCd: string, qty: number, ordNoTmp: string): any {
    let member = AuthUtil.getLoginedInfo();
    if (member) {
      let params = {
        comId: environment.comId,
        userid: member.userid,
        userKindCd: member.userKindCd,
        cntCd: member.cntCd,
        pathCD: environment.comCd + "T30",
        pdtCd: pdtCd,
        qty: qty,
        workUser: member.userid,
        workKind: "M",
        directYN: "Y",
        ordNoTmp: ordNoTmp
      };
      return params;
    }
    return null;
  }

  	/**
	 * get length cart when not login
	 * @returns 0 or 1
	 */
	static getLengthCartNotLogin() {
		let guestCart : OrdPdtModel[] = JSON.parse(sessionStorage.getItem(CommonConstant.GUEST_CART)!) as OrdPdtModel[];
		return (ValidationUtil.isNotNullAndNotEmpty(sessionStorage.getItem(CommonConstant.CART_INFO_TMP)) ||
				ValidationUtil.isNotNullAndNotEmpty(sessionStorage.getItem(CommonConstant.LINK_CART)) ||
				(ValidationUtil.isNotNullAndNotEmpty(guestCart) && guestCart.length !== 0)) ? 1 : 0;
	}

	static getParamsAddListItemToCart(products : any[], ordNoTmp : string, workKind: string): any {
		let member = AuthUtil.getLoginedInfo();
		let memberLinkGuest = AuthUtil.getConsumerInformation();
		let userId = '';
		let userKindCd = '';
		let workUser = '';
		let cntCd = '';
		let lang: string = CommonUtils.getLang();

		if(member) {
			userId = ConvertUtil.convertToString(member.userid);
			userKindCd = ConvertUtil.convertToString(member.userKindCd);
			workUser = ConvertUtil.convertToString(member.userid);
			cntCd = ConvertUtil.convertToString(member.cntCd);
		} else if(memberLinkGuest && (CommonUtils.isLinkGuest() || CommonUtils.isLinkCart())) {
			userId = ConvertUtil.convertToString(memberLinkGuest.userid);
			userKindCd = ConvertUtil.convertToString(memberLinkGuest.userKindCd);
			workUser = ConvertUtil.convertToString(memberLinkGuest.userid);
			cntCd = ConvertUtil.convertToString(memberLinkGuest.cntCd);
			ordNoTmp = ConvertUtil.convertToString(sessionStorage.getItem(CommonConstant.ORDER_NO_TMP));
		} else {
			userId = environment.comCd + "00000000";
			userKindCd = "U00";
			workUser = environment.comCd + "00000000";
			cntCd = environment.comCd + "001";
		}

		return {
		  comId: environment.comId,
		  userid: userId,
		  userKindCd: userKindCd,
		  cntCd: cntCd,
		  pathCD: environment.comCd + "T30",
		  pdtDtos: products,
		  workUser: workUser,
		  workKind : workKind,
		  directYN: "Y",
		  ordNoTmp : ordNoTmp,
		  lang: lang.toUpperCase()
		};
	}

	static getParamsUpdateOrderYN(pdtCd : string, checkYN : string, ordNoTmp : string, checkAll : string): any {
		let member = AuthUtil.getLoginedInfo();
		let memberLinkGuest = AuthUtil.getConsumerInformation();
		let userId = '';

		if(member) {
			userId = ConvertUtil.convertToString(member.userid);
		} else if(memberLinkGuest && (CommonUtils.isLinkCart() || CommonUtils.isLinkGuest())) {
			userId = ConvertUtil.convertToString(memberLinkGuest.userid);
			ordNoTmp = ConvertUtil.convertToString(sessionStorage.getItem(CommonConstant.ORDER_NO_TMP));
		} else {
			userId = environment.comCd + "00000000";
		}

		return {
		  comid: environment.comId,
		  userid: userId,
		  pathCD: environment.comCd + "T30",
		  workKind : 'M',
		  directYN: "Y",
		  ordNoTmp : ordNoTmp,
		  lang: environment.default_lang,
		  status : checkYN,
		  pdtCd : pdtCd,
		  checkAll : checkAll
		};
	}

	static getParamRemoveCart(products : any[], ordNoTmp : string, checkAll : string) {
		let member = AuthUtil.getLoginedInfo();
		let memberLinkGuest = AuthUtil.getConsumerInformation();
		let userId = "";

		if(AuthUtil.isLogined() && member) {
			userId = ConvertUtil.convertToString(member.userid);
		} else if(memberLinkGuest && (CommonUtils.isLinkGuest() || CommonUtils.isLinkCart())) {
			userId = ConvertUtil.convertToString(memberLinkGuest.userid);
			ordNoTmp = ConvertUtil.convertToString(sessionStorage.getItem(CommonConstant.ORDER_NO_TMP));
		} else {
			userId = environment.comCd + "00000000";
		}

		return {
			userid : userId,
			pdtDtos : products,
			ordNoTmp : ordNoTmp,
			comId : environment.comId,
			checkAll : checkAll,
			workUser : userId
		}
	}


	/**
	 *
	 * @param products : OrdPdtModel[] | PdtDetailModel | OrdPdtModel
	 * @returns  array or object
	 */
	static mapOrderProduct(products : OrdPdtModel[] | PdtDetailModel | OrdPdtModel) {
		let productConverter : {pdtCd : String, qty : String, cateNameOrg : String}[] = [];
		if(products instanceof Array) {
		  products.forEach(v => {
			productConverter.push({pdtCd : v.pdtCd, qty : ConvertUtil.convertToString(v.qty), cateNameOrg : v.cateNameOrg});
		  });
		} else {
		  productConverter.push({pdtCd : products.pdtCd, qty : ConvertUtil.convertToString(products.qty), cateNameOrg : products.cateNameOrg});
		}
		return productConverter;
	}

	static getParamsLoadCartInfo(orderNoTmp? : string) {
		let member = AuthUtil.getLoginedInfo();
		let memberLinkGuest = AuthUtil.getConsumerInformation();
		let userId = '';
		let ctrCd = '';

		if(member) {
			userId = ConvertUtil.convertToString(member.userid);
		  	ctrCd = ConvertUtil.convertToString(member.ctrCd);
		} else if(memberLinkGuest && (CommonUtils.isLinkGuest() || CommonUtils.isLinkCart())) {
			userId = ConvertUtil.convertToString(memberLinkGuest.userid);
		  	ctrCd = ConvertUtil.convertToString(memberLinkGuest.ctrCd);
			orderNoTmp = ConvertUtil.convertToString(sessionStorage.getItem(CommonConstant.ORDER_NO_TMP));
		} else {
			userId = environment.comCd + "00000000";
			ctrCd = environment.default_lang;
		}

		let params = {
		  comId : environment.comId,
		  userId : userId,
		  ordNoTmp : orderNoTmp,
		  ctrCd : ctrCd
		};
		return params;
	}
}
