export class MyProgramConstant {
	static urlShopping 			= "/shopping-mall";
	static urlMyOffice 			= "/my-office";
 	static URL_AUTO_SHIP_REGISTER= "/my-office/auto-ship/register";

  	static kindMenuTopLeft 		= "ml";
  	static kindMenuTopRight 		= "mr";
  	static kindMenuHome			= "mh";
  	static kindMenuOffice 		= "mo";
  	static kindMenuShopping 		= "ms";
  	static kindMenuFooter 		= "mf";
  	static kindSidebar 			= "mb";

  	static officeId 				= "ml.My-office";
  	static shoppingId			= "ml.Shopping-mall";
  	static homeId 				= "ml.Home";


	static LIST_PRG_ID_HIDE_AFFILIATE_PARTNER : string [] = [
		"ml.Office.Member-management.Member-inquiry" ,
		"ml.Office.Member-management.Line-lineage",
		"ml.Office.Member-management.Binary-leg-search",
		"ml.Office.Member-management.Box-lineage",
		"ml.Office.Member-management.Member-analysis",
		"ml.Office.Member-management.Holding-tank",
		"ml.Office.Order-management.Order-product-search",
		"ml.Office.Order-management.Order-calendar",
		"ml.Office.Order-management.Monthly-order-count",
		"ml.Office.Order-management.Performance-By-League",
		"ml.Office.Benefit-management.Benefit-calendar",
		"ml.Office.Benefit-management.Benefit-accounting-inquiry",
		//"ml.Office.Auto-ship", // HIDDEN menu autoship

	]
}
