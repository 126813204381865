import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthUtil } from '@app/common/util/auth.util';
import { CommonConstant } from '@app/common/constant/common.constant';
import { CommonUtils } from '@app/common/util/common.util';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  constructor(private _router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const isLogined = AuthUtil.isLogined();
    if (!isLogined) {
      this._router.navigate(["/login"]);
    }
    if(AuthUtil.getLoginedInfo()?.userKind.slice(2) == 'U00' &&  CommonUtils.isPassLink(state.url)){
      this._router.navigate(["/"]);
    }
    return isLogined;
  }
}
