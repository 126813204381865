import { createAction, props } from '@ngrx/store';

import { OrdPdtModel } from '@app/models/shoppingmall/order-pdt.model';
import { OrderMstModel } from '@app/models/myoffice/order/order-mst.model';
import { ResultProc } from '@app/models/system/result-proc.model';
import { PdtDetailModel } from '@app/models/shoppingmall/pdt-detail.model';

// cart items
export const loadCartItems = createAction(
	"[Order Register API] load card items",
	props<{ userid: string, ordNoTmp: any }>()
);

export const loadCartItemsSuccess = createAction(
	"[Order Register API] load card items success",
	props<{ items: OrdPdtModel[] }>()
);

export const loadCartItemsFailure = createAction(
	"[Order Register API] load card items failure",
	props<{ msg: any }>()
);

// cart info
export const loadCartInfo = createAction(
	"[Order Register API] load card info",
	props<{ userId: string, ctrCd: string, workKind: string, page: number, len: number }>()
);

export const loadCartInfoSuccess = createAction(
	"[Order Register API] load card info success",
	props<{ info: OrderMstModel[] }>()
);

export const loadCartInfoFailure = createAction(
	"[Order Register API] load card info failure",
	props<{ msg: any }>()
);

// one cart info
export const loadOneCartInfo = createAction(
	"[Order Register API] load one card info",
	props<{ userid: string, ctrCd: string, ordNoTmp: any }>()
);

export const loadOneCartInfoSuccess = createAction(
	"[Order Register API] load one card info success",
	props<{ info: OrderMstModel }>()
);

export const loadOneCartInfoFailure = createAction(
	"[Order Register API] load one card info failure",
	props<{ msg: any }>()
);

export const countCartInfo = createAction(
	"[Order Register API] count card info",
	props<{ userid: string }>()
);

export const countCartInfoSuccess = createAction(
	"[Order Register API] count card info success",
	props<{ total: Number }>()
);

export const countCartInfoFailure = createAction(
	"[Order Register API] count card info failure",
	props<{ msg: any }>()
);

// add to cart
export const addToCart = createAction(
	"[Order Register API] add to cart",
	props<{ params: any }>()
);

export const addToCartSuccess = createAction(
	"[Order Register API] add to cart success",
	props<{ result: ResultProc }>()
);

export const addToCartFailure = createAction(
	"[Order Register API] add to cart failure",
	props<{ msg: any }>()
);

// remove cart item
export const removeCartItem = createAction(
	"[Order Register API] remove cart item",
	props<{ params: any }>()
);

export const removeCartItemSuccess = createAction(
	"[Order Register API] remove cart item success",
	props<{ result: ResultProc }>()
);

export const removeCartItemFailure = createAction(
	"[Order Register API] remove cart item failure",
	props<{ msg: any }>()
);

// cancel order
export const cancelOrder = createAction(
	"[Order Register API] cancel order",
	props<{ userid: string, ordNoTmp: number }>()
);

export const cancelOrderSuccess = createAction(
	"[Order Register API] cancel order success",
	props<{ result: ResultProc }>()
);

export const cancelOrderFailure = createAction(
	"[Order Register API] cancel order failure",
	props<{ msg: any }>()
);

// set is buy now
export const setIsBuyNow = createAction(
	"[Cart] set is buy now",
	props<{ isBuyNow: boolean }>()
);

// set reload
export const setReload = createAction(
	"[Cart] set reload cart items and cart info",
	props<{ reload: boolean }>()
);

// show alert
export const setShowAlert = createAction(
	"[Cart] set show alert",
	props<{ show: boolean }>()
)

// clear cart
export const clearCart = createAction("[Card] clear cart");

// all cart items
export const loadAllCartItems = createAction(
	"[Order Register API] load all card items",
	props<{ userid: string }>()
);

export const loadAllCartItemsSuccess = createAction(
	"[Order Register API] load all card items success",
	props<{ items: OrdPdtModel[] }>()
);

export const loadAllCartItemsFailure = createAction(
	"[Order Register API] load all card items failure",
	props<{ msg: any }>()
);

// cart info not login
export const loadCartInfoNotLogin = createAction(
	"[Order Register API] load card info not login",
	props<{ ordNoTmp: number }>()
);

export const loadCartInfoNotLoginSuccess = createAction(
	"[Order Register API] load card info not login success",
	props<{ info: OrderMstModel }>()
);

export const loadCartInfoNotLoginFailure = createAction(
	"[Order Register API] load card info not login failure",
	props<{ msg: any }>()
);

// add to cart
export const addToCartJointMall = createAction(
	"[Order Register API] add to cart joint mall",
	props<{ params: any }>()
);

export const addToCartJointMallSuccess = createAction(
	"[Order Register API] add to cart joint mall success",
	props<{ result: ResultProc }>()
);

export const addToCartJointMallFailure = createAction(
	"[Order Register API] add to cart joint mall failure",
	props<{ msg: any }>()
);

// add to cart tmp
export const actionAddToCartTmp = createAction(
	"[Order Register API] add to cart tmp",
	props<{ params: any }>()
);

export const actionAddToCartTmpSuccess = createAction(
	"[Order Register API] add to cart tmp success",
	props<{ resultAddToCartTmp: ResultProc }>()
);

export const actionAddToCartTmpFailure = createAction(
	"[Order Register API] add to cart tmp failure",
	props<{ msg: any }>()
);

// cart info2
export const actionGetCartInfo2 = createAction(
	"[Order Register API] get cart info 2",
	props<{ params: any }>()
);

export const actionGetCartInfo2Success = createAction(
	"[Order Register API] get cart info 2 success",
	props<{ info: OrderMstModel }>()
);

export const actionGetCartInfo2Failure = createAction(
	"[Order Register API] get cart info 2 failure",
	props<{ msg: any }>()
);

// cart items 2
export const actionGetCartItems2 = createAction(
	"[Order Register API] get cart 2 items",
	props<{ params: any }>()
);

export const actionGetCartItems2Success = createAction(
	"[Order Register API] get cart items 2 success",
	props<{ items: OrdPdtModel[] }>()
);

export const actionGetCartItems2Failure = createAction(
	"[Order Register API] get cart items 2 failure",
	props<{ msg: any }>()
);

// update order YN
export const actionUpdateOrderYN = createAction(
	"[Order Register API] update order YN",
	props<{ params: any }>()
);

export const actionUpdateOrderYNSuccess = createAction(
	"[Order Register API] update order YN success",
	props<{ msg: any }>()
);

export const actionUpdateOrderYNFailure = createAction(
	"[Order Register API] update order YN failure",
	props<{ msg: any }>()
);

// action get guest cart info
export const actionGetGuestCartInfo = createAction(
	"[Order Register API] get guest cart info"
);

export const actionGetGuestCartInfoSuccess = createAction(
	"[Order Register API] get guest cart info success",
	props<{ item: OrderMstModel }>()
);

export const actionGetGuestCartInfoFailure = createAction(
	"[Order Register API] get guest cart info failure",
	props<{ msg: any }>()
);

// action get guest cart item
export const actionGetGuestCartItem = createAction(
	"[Order Register API] get guest cart item"
);

export const actionGetGuestCartItemSuccess = createAction(
	"[Order Register API] get guest cart item success",
	props<{ items: OrdPdtModel[] }>()
);

export const actionGetGuestCartItemFailure = createAction(
	"[Order Register API] get guest cart item failure",
	props<{ msg: any }>()
);

// action save guest cart item
export const actionSaveGuestCartItem = createAction(
	"[Order Register API] save guest cart item",
	props<{ item: PdtDetailModel | OrdPdtModel[] | OrdPdtModel }>()
);

export const actionSaveGuestCartItemSuccess = createAction(
	"[Order Register API] save guest cart item success",
	props<{ item: OrdPdtModel[] }>()
);

export const actionSaveGuestCartItemFailure = createAction(
	"[Order Register API] save guest cart item failure",
	props<{ msg: any }>()
);

// remove cart item guest user
export const actionRemoveCartItemsGuest = createAction(
	"[Order Register API] remove cart item guest user",
	props<{ params: any, kind: string }>()
);

export const actionRemoveCartItemsGuestSuccess = createAction(
	"[Order Register API] remove cart item success guest user",
	props<{ msgRemoveCartItems: string }>()
);

export const actionRemoveCartItemsGuestFailure = createAction(
	"[Order Register API] remove cart item failure guest user",
	props<{ msgRemoveCartItems: any }>()
);

// update order YN guest cart
export const actionUpdateOrderYNGuestCart = createAction(
	"[Order Register API] update order YN guest cart",
	props<{ item: PdtDetailModel | OrdPdtModel[] | OrdPdtModel, checkAll: boolean, state: string }>()
);

export const actionUpdateOrderYNGuestCartSuccess = createAction(
	"[Order Register API] update order YN guest cart success",
	props<{ msgUpdateOrderYN: string }>()
);

export const actionUpdateOrderYNGuestCartFailure = createAction(
	"[Order Register API] update order YN guest cart failure",
	props<{ msgUpdateOrderYN: string }>()
);

// cart info link cart and link guest
export const actionGetCartInfoLink = createAction(
	"[Order Register API] get cart info link",
	props<{ params: any }>()
);

export const actionGetCartInfoLinkSuccess = createAction(
	"[Order Register API] get cart info link success",
	props<{ info: OrderMstModel }>()
);

export const actionGetCartInfoLinkFailure = createAction(
	"[Order Register API] get cart info link failure",
	props<{ msg: any }>()
);

// cart items link cart and link guest
export const actionGetCartItemsLink = createAction(
	"[Order Register API] get cart items link",
	props<{ params: any }>()
);

export const actionGetCartItemsLinkSuccess = createAction(
	"[Order Register API] get cart items link success",
	props<{ items: OrdPdtModel[] }>()
);

export const actionGetCartItemsLinkFailure = createAction(
	"[Order Register API] get cart items link failure",
	props<{ msg: any }>()
);

// cart info
export const actionLoadCartInfoByWorkKind = createAction(
	"[Order Register API] load card info by work kind",
	props<{ userId: string, ctrCd: string, workKind: string, page: number, len: number }>()
);

export const actionLoadCartInfoByWorkKindSuccess = createAction(
	"[Order Register API] load card info by work kind success",
	props<{ cartInfoByWorkKind: OrderMstModel[] }>()
);

export const actionLoadCartInfoByWorkKindFailure = createAction(
	"[Order Register API] load card info by work kind failure",
	props<{ msg: any }>()
);

export const actionGetTotalCartInfoByWorkKind = createAction(
	"[Order Register API] get total cart info",
	props<{ userId: string, workKind: string }>()
);

export const actionGetTotalCartInfoByWorkKindSuccess = createAction(
	"[Order Register API] get total cart info success",
	props<{ totalCartInfoByWorkKind: Number }>()
);

export const actionGetTotalCartInfoByWorkKindFailure = createAction(
	"[Order Register API] get total cart info failure",
	props<{ msg: any }>()
);

// ord check
export const actionCheckAllItems = createAction(
	"[Card] ord check",
	props<{ status: string, pdtCd: string, ordNoTmp: number, checkAll: string, userId: string }>()
);

export const actionCheckAllItemsSuccess = createAction(
	"[Card] ord check success",
	props<{ msg: any }>()
);

export const actionCheckAllItemsFailure = createAction(
	"[Card] ord check failure",
	props<{ msg: any }>()
);


