import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ValidationUtil } from '@app/common/util/validation.util';
import { Login2State, getUserInformationLinkGuest } from '@app/selectors/system/login2.select';
import { actionGetUserInformationLinkGuest } from '@app/actions/system/login2.action';

import { PageHeadingState, showPageHeading } from './selectors/page-heading.selector';
import { ConvertUtil } from './common/util/convert.util';

import { Member } from '@app/models/myoffice/member/member.model';
import { CommonConstant } from './common/constant/common.constant';
import { SessionService } from './services/system/session.service';
import { CartState } from './selectors/shoppingmall/cart.selector';
import { actionGetCartInfo2, actionGetCartItems2 } from './actions/shoppingmall/cart.action';
import { CartUtil } from './common/util/cart.util';
import { CommonUtils } from './common/util/common.util';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	member$ = new Observable<Member>();

	showPageHeading$ = new Observable<boolean>();

	memberLinkGuest$ = new Observable<Member>();

	constructor(private _pageHeadingStore: Store<PageHeadingState>,
		private loginStore: Store<Login2State>,
		private sessionService: SessionService,
		private _cartStore: Store<CartState>,
		private _translate: TranslateService,
		private router: Router) {
			this.router.events.subscribe(event => {
				if (event instanceof NavigationEnd) {
				  window.scrollTo(0, 0);
				}
			  });	
		this.showPageHeading$ = this._pageHeadingStore.select(showPageHeading);
		this.memberLinkGuest$ = this.loginStore.select(getUserInformationLinkGuest);
		
	}

	ngOnInit(): void {
		const urlParams = new URLSearchParams(window.location.search);
		const userId = urlParams.get('userid');
		const ordNoTmp = urlParams.get('ordNoTmp');

		if (ValidationUtil.isNotNullAndNotEmpty(userId)) {
			if (window.location.href.indexOf('my-office') === -1) {
				this.loginStore.dispatch(actionGetUserInformationLinkGuest(ConvertUtil.convertToString(userId)));
				if (ValidationUtil.isNotNullAndNotEmpty(ordNoTmp)) {
					this.memberLinkGuest$.subscribe(res => {
						this.sessionService.setItem(CommonConstant.USER_ID, res.userid);
						this.sessionService.setItem(CommonConstant.ORDER_NO_TMP, ConvertUtil.convertToString(ordNoTmp));
						if (ValidationUtil.isNotNullAndNotEmpty(res)) {
							this.sessionService.setItem(CommonConstant.LINK_GUEST, CommonConstant.NO);
							this.sessionService.setItem(CommonConstant.LINK_CART, CommonConstant.YES);
							if (CommonUtils.isLinkCart()) {
								this._cartStore.dispatch(actionGetCartInfo2({ params: CartUtil.getParamsLoadCartInfo(ConvertUtil.convertToString(ordNoTmp)) }));
								this._cartStore.dispatch(actionGetCartItems2({ params: CartUtil.getParamsLoadCartInfo(ConvertUtil.convertToString(ordNoTmp)) }));
							}
						}
					});
				}
			}
		}

		let messages = CommonUtils.getMessages();
		this._translate.use(messages);
	}
}
