import { DateFilterModel } from "@app/models/components/date-filter.model";
import { CommonConstant } from "../constant/common.constant";
import { AuthUtil } from "./auth.util";
import { ConvertUtil } from "./convert.util";
import { ValidationUtil } from "./validation.util";
import { environment } from "@enviroments/environment";
import { LangConstant } from "../constant/lang.constant";


export class CommonUtils {

	static isConsumer(): boolean {
		if (AuthUtil.isLogined()) {
			return ConvertUtil.convertToString(AuthUtil.getLoginedInfo()?.userKindCd).includes("U00");
		}
		let infoConsumer = AuthUtil.getConsumerInformation();
		if (ValidationUtil.isNotNullAndNotEmpty(infoConsumer)) {
			return ConvertUtil.convertToString(infoConsumer?.userKindCd).includes("U00");
		}
		return false;
	}

	static getUserKind(): string {
		if (AuthUtil.isLogined()) {
			return ConvertUtil.convertToString(AuthUtil.getLoginedInfo()?.userKindCd);
		}
		return ConvertUtil.convertToString(AuthUtil.getInfoUserId2()?.userKindCd);
	}

	static plus(number1: any, number2: any): number {
		return Number(number1) + Number(number2);
	}

	static minus(number1: any, number2: any): number {
		return Number(number1) - Number(number2);
	}

	static getListFromCurrentMonth(currentMonth: number) {
		let totalMonth = 12 - currentMonth;
		if (totalMonth === 0 || currentMonth > 12 || currentMonth <= 0) {
			return Array.from({ length: 12 }, (e, i) => ConvertUtil.convertToZeroDecimal(CommonUtils.plus(i, 1)));
		}
		return Array.from({ length: totalMonth }, (e, i) => ConvertUtil.convertToZeroDecimal(CommonUtils.plus(currentMonth, i + 1)));
	}

	static getListFromCurrentYear(currentYear: number) {
		return Array.from({ length: 5 }, (e, i) => {
			return ConvertUtil.convertToZeroDecimal(CommonUtils.plus(currentYear, i));
		});
	}

	static getUserId2(): string {
		if (AuthUtil.getLoginedInfo()) {
			return ConvertUtil.convertToString(AuthUtil.getLoginedInfo()?.userid2);
		}
		if (AuthUtil.getInfoUserId2()) {
			return ConvertUtil.convertToString(AuthUtil.getInfoUserId2()?.userid2);
		}
		return '';
	}

	static isLinkCart(): boolean {
		return ValidationUtil.isAllNotNullAndNotEmpty(sessionStorage.getItem(CommonConstant.LINK_CART), sessionStorage.getItem(CommonConstant.ORDER_NO_TMP));
	}

	static isLinkGuest(): boolean {
		return ValidationUtil.isAllNotNullAndNotEmpty(sessionStorage.getItem(CommonConstant.LINK_GUEST), sessionStorage.getItem(CommonConstant.CONSUMER))
			&& (sessionStorage.getItem(CommonConstant.LINK_GUEST) === CommonConstant.YES);
	}

	static isGuestCart(): boolean {
		return ValidationUtil.isNotNullAndNotEmpty(sessionStorage.getItem(CommonConstant.GUEST_CART));
	}

	/**
	 * map object to another object
	 * @param source
	 * @returns object converted
	 */
	static mapObject<TSource extends {}, TTarget>(source: TSource): TTarget {
		let result: TTarget = {} as TTarget;
		return Object.assign(source, result);
	}

	static getYN(value: string) {
		return (value === null || value === CommonConstant.NO || value === undefined || value.length <= 0) ? false : true;
	}

	static isValidDateFilter(filter: DateFilterModel): boolean {
		if (ValidationUtil.isNullOrEmpty(filter)) return false;
		if (ValidationUtil.isNullOrEmpty(filter.fromDate) || ValidationUtil.isNullOrEmpty(filter.toDate)) return false;
		if (ValidationUtil.isNullOrEmpty(filter.fromDate.date.value) ||
			ValidationUtil.isNullOrEmpty(filter.fromDate.month.value) ||
			ValidationUtil.isNullOrEmpty(filter.fromDate.year.value)) return false;
		if (ValidationUtil.isNullOrEmpty(filter.toDate.date.value) ||
			ValidationUtil.isNullOrEmpty(filter.toDate.month.value) ||
			ValidationUtil.isNullOrEmpty(filter.toDate.year.value)) return false;

		return true;
	}

	static getDateNow() {
		let currentDate = new Date();

		const y = currentDate.getFullYear();
		const m = currentDate.getMonth() + 1 < 10 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1;
		const d = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate();

		return ConvertUtil.convertToString('' + y + m + d);
	}

	/**
	 *
	 * @param value
	 * @returns the value after round half. ex: 2.3 -> 2
	 */
	static roundHalf(value: number) {
		return Math.round(value * 2) / 2;
	}

	static equalsAny(str: string, ...searchStr: string[]) {
		if (searchStr.length !== 0) {
			for (let next of searchStr) {
				if (next === str) {
					return true;
				}
			}
		}
		return false;
	}

	static hasOwnProperty<X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
		return obj.hasOwnProperty(prop)
	}


	static getMessages(): string {
		return 'messages.' + CommonUtils.getLangMessage();
	}

	static getLangMessage(): string {
		let res = environment.default_lang.toLowerCase();
		let lang = CommonUtils.getLang();

		switch (lang) {
			case 'kr':
				res = 'kr';
				break;
			case 'us':
				res = 'us';
				break;
			case 'th':
				res = 'th';
				break;
			case 'cn':
				res = 'cn';
				break;
			default:
				res = 'us';
				break;
		}

		return res;
	}

	static getLang(): string {
		let loginedInfo = AuthUtil.getLoginedInfo();
		let lang = localStorage.getItem(LangConstant.STR_LANG);

		if (ValidationUtil.isNullOrEmpty(lang)) {
			// if (loginedInfo) {
			// 	lang = ConvertUtil.convertToString(loginedInfo.ctrCd);
			// } else {
				lang = environment.default_lang;
			// }
			localStorage.setItem(LangConstant.STR_LANG, lang);
		}

		return ConvertUtil.convertToString(lang).toLowerCase();
	}

	static isLangUS() {
		return (CommonUtils.getLang() == 'us' || CommonUtils.getLang() == 'US');
	}

	static isPassLink(link:string){
		let LINK_URL_ACCESS_PASS : string []= ['my-page' , 'order-details-inquiry' , 'cart' , 'report/order']
		for( const item of LINK_URL_ACCESS_PASS){
			if(link.includes(item)){
				return false
			}
		}
		return true;
	}


}

